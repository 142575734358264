import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Container from '../components/container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import YouTube from 'react-youtube'

function YouTubeGetID(url){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
 }

export default function Article({ pageContext: { title, image, text, date, video } }) {
    const navigation = [
        { name: 'Всі новини', href: '/news', key: 52 },
        { name: 'На головну', href: '/', key: 53 },
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="image" content={image.relativePath} />
                <title>{title}</title>
                <meta name="theme-color" content="#53C82A" />
                <link rel="apple-touch-icon" href="logo.png"></link>
            </Helmet>
            <div className="bg-hero-image bg-no-repeat bg-cover bg-top">
                <Container>
                    <Navbar navigation={navigation} />
                </Container>
            </div>

            <Container>
                <div className='mt-8 lg:mt-12 md:mx-8 overflow-hidden'>
                    <h1 className="text-3xl text-center tracking-tight font-bold sm:text-5xl md:text-6xl">
                        {title}
                    </h1>
                    <div className="flex flex-col items-center justify-between lg:mt-4">
                        <div className="md:mb-0 flex justify-center">
                            <div className="w-auto flex items-center">
                                {video ? <YouTube videoId={YouTubeGetID(video)} /> : <GatsbyImage className="w-auto h-full" image={getImage(image.childImageSharp.gatsbyImageData)} alt={title} />}
                            </div>
                        </div>
                        <article className="md:w-3/4 lg:mt-0 m-8 lg:w-full overflow-hidden">
                            <div className="flex flex-col py-12 text-xl">
                                <p className="mb-4">
                                    {date}
                                </p>
                                <p className="mb-4">
                                    {text}
                                </p>
                            </div>
                        </article>
                    </div>

                </div>
            </Container>

            <div className="bg-green-400">
                <Container>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}
